$red : #d43153;
$light : #FFF;
$light-pink: #f5e3ec;
$dark : #3b2f24;
$path : "/kk";

@font-face {
    font-family: "BebasNeue";
    src: url("/fonts/bebasneue-webfont.eot");
    src: url("/fonts/bebasneue-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/bebasneue-webfont.woff") format("woff"), url("/fonts/bebasneue-webfont.ttf") format("truetype"), url("/fonts/bebasneue-webfont.svg#bebasneue") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "FilmotypeLucky";
    src: url("/fonts/FilmotypeLucky-webfont.eot");
    src: url("/fonts/FilmotypeLucky-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/FilmotypeLucky-webfont.woff") format("woff"), url("/fonts/FilmotypeLucky-webfont.ttf") format("truetype"), url("/fonts/FilmotypeLucky-webfont.svg#FilmotypeLucky") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "DroidSans";
    src: url("/fonts/DroidSans-webfont.eot");
    src: url("/fonts/DroidSans-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/DroidSans-webfont.woff") format("woff"), url("/fonts/DroidSans-webfont.ttf") format("truetype"), url("/fonts/FDroidSans-webfont.svg#DroidSans") format("svg");
    font-weight: normal;
    font-style: normal;
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}