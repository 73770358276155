.loading {

    &.true {
        display: block;
    }

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.75);
    z-index: 100000;
    display: none;

     .spinner {
        position: absolute;
        top: calc(50% - 10px);
        width: 100%;
        text-align: center;
        img {
            width: 50px;
            height: auto;
        }
    }
}

