body {
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100vw;
    background: #f0dae6;
}

html,
body {
    // position: fixed;
    position: absolute;
    overflow: hidden;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}

body {
    margin: 0px;
}

.container {
    width:auto;
    text-align:center;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
     background: #f0dae6;
    background: -moz-radial-gradient(center, ellipse farthest-corner, #f0dae6 0%, #e3bacf 97%);
    background: -webkit-radial-gradient(center, ellipse farthest-corner, #f0dae6 0%, #e3bacf 97%);
    background: radial-gradient(ellipse at center, #f0dae6 0%, #e3bacf 97%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f0dae6', endColorstr='#e3bacf', GradientType=1);
}

.App {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}

.wrapper {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}