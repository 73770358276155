canvas {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    // height: 100%;
}

.shake-wrapper {

    cursor: pointer;

    opacity: 1;
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
    position: absolute;
    display: block;
    top: calc(50% - 48px);
    width: 100%;
    z-index: 1000;

    .shake {   
        z-index: 1000;
        width: 138px;
        height: auto;
        margin: auto;
    }

    .shake-instuctions {
        width: 100%;
        text-align: center;
        color: $light;
        z-index: 1;
        font-family: "BebasNeue";
        text-transform: uppercase;
        font-size: 22px;
        display: block;
        @include grid-media($article-layout-grid--dt-up) { 
            font-size: 25px;
            text-decoration: underline;
        }
    }

    &.jiggletrue {
        animation: shake 1s; 
        animation-iteration-count: infinite; 
    }

    &.jigglefalse {
        
        top: calc(50% - 50px);
        // left: calc(50% -100px);
        padding-top: 50px;
        padding-bottom: 50px;

        .shake {
            display: none
        }
    }
}

.render-canvas {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 11111;
}

.shake-wrapper .shakentrue {
    opacity: 0;
}