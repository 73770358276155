.love_message_maker {
  margin: auto;
  height: auto;
  width: 70%;

  @include grid-media($article-layout-grid--tablet-up) {
    width: 492px;
  }

  @include grid-media($article-layout-grid--dt-up) {
    width: 400px;
  }
}