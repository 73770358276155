.krispy_kreme_header {

    height: 44px;
    width: 100%;
    z-index: 100;

    &.locked {
        position: absolute;
    }

    img {
        margin-top: 25px;
        height: 35px;
        width: auto;
    }

    @include grid-media($article-layout-grid--tablet-up) {
        margin-top: 50px;
        margin-bottom: 50px;

         img {
            margin-top: 25px;
            height: 60px;
            width: auto;
        }
    }

    @include grid-media($article-layout-grid--dt-up) {
        
         img {
            margin-top: 0px;
         }
    }
}