.krispy_kreme_valentines_form {

    margin-top: 50px;

    @include grid-media($article-layout-grid--tablet-up) {
        margin-top: 60px;
    }

    @include grid-media($article-layout-grid--dt-up) {
        margin-top: 70px;
    }
    
    @include grid-column(24);

    .form-group {
        @include grid-column(20);
        @include grid-push(2);

        position: relative;
        z-index: 1;
        display: inline-block;
        margin-top: 30px;

        &:nth-last-child(2) {
            margin-top: 0px;
        }
  
        @include grid-media($article-layout-grid--tablet-up) {
           
           @include grid-column(11);
           @include grid-push(1);
           margin-right: 15px;

            &:nth-last-child(2) {
                margin-top: 50px;
                @include grid-push(0);
            }

            margin-top: 50px;
        }

        @include grid-media($article-layout-grid--dt-up) {

            margin-top: 50px;

            @include grid-column(6);
            @include grid-push(6);

            &:nth-last-child(2) {
                    margin-top: 50px;
                @include grid-push(0);
            }
        }        
    }
    
    legend {
        color: $red;
        font-family: "FilmotypeLucky", "Arial";
        margin: auto;
        margin-top: 35px;
        font-size: 20px;
        letter-spacing: 1px;
        line-height: 19px;
        max-width: 250px;

        @include grid-media($article-layout-grid--tablet-up) {
            font-size: 28px;
            line-height: 32px;
            max-width: 350px;
        }

        @include grid-media($article-layout-grid--dt-up) {
            font-size: 38px;
            line-height: 44px;
            max-width: 490px;
        }
    }

    label {
        display: block;
        color: $dark;
        font-family: "DroidSans", "Arial";
        font-size: 12px;
        background: none;
        border: none;
        text-align: left;
        padding-left: 25px;

        @include grid-media($article-layout-grid--tablet-up) {
            font-size: 16px;
        }

        @include grid-media($article-layout-grid--dt-up) {
            padding-left: 25px;
            font-size: 18px;
        }
    }

    input {
        color: $dark;
        font-family: "DroidSans", "Arial";
        background: none;
        border: none;
        padding-left: 25px;
        padding-right: 25px;
        background: $light-pink;
        margin-top: 20px;
        margin-bottom: 20px;
        width: auto;
        display: block;
        outline: none;
        border-radius: 0px;
        font-size: 12px;
        font-weight: 800;
        text-align: left;
        width: calc(100% - 50px);
        padding-top: 15px;
        padding-bottom: 15px;
        
        @include grid-media($article-layout-grid--tablet-up) {
            padding-top: 25px;
            padding-bottom: 25px;
            font-size: 22px;
        }

        @include grid-media($article-layout-grid--dt-up) {
            font-size: 24px;
        }

        &::placeholder {
            color: $dark;
            display: none;
        }
    }

    .srv-validation-message {
        font-family: "DroidSans", "Arial";
        text-align: left;
        color: $red;
        font-size: 12px;
        padding-left: 25px;

        @include grid-media($article-layout-grid--dt-up) {
            font-size: 18px;
        }
    }

    .form-button {

        clear: both;

        .krispy_button {
            font-family: "BebasNeue", "Arial";
            padding: 13px 30px;
            border: none;
            background-color: $red;
            color: $light;
            font-size: 20px;
            text-transform: uppercase;

            @include grid-media($article-layout-grid--tablet-up) {
                margin-top: 40px;
                font-size: 28px;
                padding: 25px 50px;
                margin-bottom: 50px;
            }

             @include grid-media($article-layout-grid--dt-up) {
                margin-top: 40px;
             }
        }
    }   
}

.button_wrapper {

    $button_gap : 8px;

    position: absolute;
    bottom: 8px;
    left: 8px;
    width: calc(100% - 16px);

    @include grid-media($article-layout-grid--dt-up) {
        width: 50%;
        left: 25%; 
        bottom: 53px;

        @media screen and (max-height: 700px) {
            bottom: 10px;
        }
    }

    .krispy_button {
        cursor: pointer;
        font-family: "BebasNeue", "Arial";
        padding: 13px 0px;
        border: none;
        background-color: $red;
        color: $light;
        font-size: 20px;
        text-transform: uppercase;
        display: block;
        margin: auto;
        margin-top: $button_gap;
        margin-bottom: $button_gap;
        text-decoration: none;

        span {
            display: inline-block;
            height: 20px;
            min-width: 20px;
            margin-left: 15px;
        }

        &.share span {
            background-image: url(/ui/facebook.png);
            background-size: 20px 20px;
        }

        &.download span {
            background-image: url(/ui/download.png);
            background-size: 20px 20px;
        }
    }

    .krispy_button {
        width: calc(50% - 16px);
        display: inline-block;
      
        
        &:nth-child(1) {
            margin-right: 16px;
        }

        &:nth-last-child(1) {
            width: calc(100% - 16px);
        }

        @include grid-media($article-layout-grid--dt-up) {

            font-size: 28px;
            padding: 25px 0;
            
            &:nth-child(1) {
                margin-right: 0;
            }

            &:nth-last-child(1) {
                margin-left: 0;
                margin-right: 0;
                width: 33%;
            }

            display: none;
            width: calc(33% - 32px);
            margin-left: 16px;
            margin-right: 16px;
            display: inline-block;
        }
    }
}

