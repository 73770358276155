.next-wrapper {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
    bottom: 150px;
    
    width: 100%;
    z-index: 1;
    overflow: hidden;
    height: 100px;

    @include grid-media($article-layout-grid--dt-up) {
        bottom: 200px;
        right: 0;
        width: 400px;
    }

    &.shakentrue {
        opacity: 1;
    }

    .next {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        color: $light;
        font-family: "BebasNeue";
        text-transform: uppercase;
        font-size: 16px;
         @include grid-media($article-layout-grid--dt-up) {
             font-size: 25px;
             text-decoration: underline;
             left: -20px;
         }
    }

    .next-line {
        position: absolute;
        left: calc(50% + 90px);
        bottom: 0;
        width: 140px; 
        height: 140px;
        background-image: url(/graphics/arrow.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        z-index: 1;
    }
}
