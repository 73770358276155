/* Intro Section to app */
$neat-grid: (
  columns: 24,
  gutter: 0rem,
  direction: ltr,
);

$article-layout-grid--tablet-up: (
  columns: 24,
  gutter: 0rem,
  media: "(min-width: 768px)",
);

$article-layout-grid--dt-up: (
  columns: 24,
  gutter: 0rem,
  media: "(min-width: 1024px)",
);

$article-layout-grid--xl-up: (
  columns: 24,
  gutter: 0rem,
  media: "(min-width: 1910px)",
);

$article-layout-grid: (
  columns: 1,
);